import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import LPLayout, { LayoutColor } from "../components/landing-page/lp-layout"
import { useTranslation } from "react-i18next"

export default function MarkdownRenderTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { i18n } = useTranslation()

  useEffect(() => {
    switch (i18n.language) {
      case "es":
        navigate("/politica-privacidad/")
        break
      case "pt":
        navigate("/politica-privacidade/")
        break
      default:
        navigate("/privacy-policy/")
        break
    }
  }, [i18n.language])

  return (
    <LPLayout color={LayoutColor.light}>
      <main
        id="markdown-page"
        className="flex flex-col items-center max-w-7xl w-full m-auto xs:mt-36 md:mt-44 px-5"
      >
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.subtitle}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </main>
    </LPLayout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        subtitle
      }
    }
  }
`
